var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('v-container',[_c('toggle-activation',{ref:"toggleActivation",on:{"doLoad":_vm.doLoad}}),_c('toggle-public',{ref:"togglePublic",on:{"doLoad":_vm.doLoad}}),_c('v-row',{staticClass:"mb-4"},[_c('v-col',{attrs:{"align":"right"}},[_c('v-btn',{staticClass:"primary",attrs:{"disabled":_vm.userRole === 'employee'},on:{"click":_vm.doOpenAddCourse}},[_vm._v(" Add course ")])],1)],1),_c('v-card',[_c('v-card-title',[_c('div',[_c('v-text-field',{staticStyle:{"width":"300px"},attrs:{"label":"Search","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.courses,"search":_vm.search,"item-key":"id","loading":_vm.isLoading,"loading-text":"Loading courses"},on:{"click:row":function($event){return _vm.doOpenRow($event)},"contextmenu:row":function($event){return _vm.doShowRow($event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return undefined},proxy:true},{key:"item.organization",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-4"},[_c('v-avatar',{attrs:{"size":"42"}},[_c('v-img',{attrs:{"contain":"","src":item.organization.logoUrl}})],1),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(item.organization.name)+" ")])],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-4 font-weight-bold text-h6"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.subtitle",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-4 text-truncate",staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(item.subtitle)+" ")])]}},{key:"item.cents",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold"},[_vm._v(" $"+_vm._s(item.cents / 100)+" ")])]}},{key:"item.isPublic",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"mt-0",attrs:{"color":"success","hide-details":"","disabled":_vm.userRole === 'employee'},on:{"click":function($event){return _vm.doTogglePublic($event, item.id)}},model:{value:(item.isPublic),callback:function ($$v) {_vm.$set(item, "isPublic", $$v)},expression:"item.isPublic"}})]}},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"mt-0",attrs:{"color":"success","hide-details":"","disabled":_vm.userRole === 'employee'},on:{"click":function($event){return _vm.doToggleActivation($event, item.id)}},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}})]}}])})],1)],1),_c('view-course',{ref:"viewCourse",on:{"doLoad":_vm.doLoad}}),_c('add-course',{ref:"addCourse",on:{"doLoad":_vm.doLoad}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }